import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import './coupon-component.scss';
import DonationForm from '../donationForm';
import { SelectButton } from 'primereact/selectbutton';
import {
  VALUE_OPTION,
  LanguageList,
  OPTION_LIST,
  getDefaultValue,
  RECURRING_LIST,
  RECURRING_OPTION,
} from '../../utils/Utils';
import AnimateHeight from 'react-animate-height';
import ConverterCommon from '../../common/converterCommon';
import { calculateMetricsByAmount, fromCO2, fromM2, getLandConversion, toCO2 } from '../converter/converter.utils';
import { parseDMS, generateMapUrl } from '../../utils/funtionCommon';
import { env } from '../../environment';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const CouponComponent = props => {
  const {
    t,
    coupon,
    campaign,
    couponDetail,
    languageDefault,
    i18n,
    parameters,
    regions,
    region,
    setRegion,
    rhythm,
    currency,
    setCurrency,
  } = props;

  let timeoutAnimation: any = null;
  const donationRef = useRef<any>(null);
  const [option, setOption] = useState(VALUE_OPTION.CLAIM);
  const [isStepDis, setIsStepDis] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const [language, setLanguage] = useState<any>(() => {
    const lang = LanguageList.find(l => l.code === parameters.getLanguage());
    return lang;
  });
  const [rhythmData, setRhythmData] = useState<any>();
  const [isRhythmNo, setIsRhythmNo] = useState(false);

  const [calcValues, setCalcValues] = useState({
    value: 0,
    amount: 0,
    area: 0,
    co2: 0,
  });

  useLayoutEffect(() => {
    if (rhythm === RECURRING_OPTION.NO) {
      setRhythmData(RECURRING_OPTION.NO);
    } else {
      const rhythmValue = rhythm
        ? RECURRING_LIST[language.code].find(item => item.code === rhythm)?.code ||
          RECURRING_LIST[language.code][0]?.code
        : RECURRING_LIST[language.code][0]?.code;
      setRhythmData(rhythmValue);
    }
  }, [rhythm]);

  useLayoutEffect(() => {
    let amount = getDefaultValue();
    const conversion = getLandConversion(region, currency.code);
    switch (true) {
      case parameters.getAmount() !== null:
        amount = parameters.getAmount();
        break;
      case parameters.getSqm() !== null:
        amount = fromM2(parameters.getSqm(), conversion.area);
        // amount = Math.round(parameters.getSqm() / conversion.area);
        break;
      case parameters.getCo2() !== null:
        amount = fromCO2(parameters.getCo2(), conversion.area, conversion.co2);
        // amount = Math.round((parameters.getCo2() / conversion.co2) / conversion.area);
        break;
    }
    const values: any = calculateMetricsByAmount(amount, region, currency.code);

    setCalcValues(values);
  }, [option]);

  const isRedeemingCampaign = !!campaign?.code;
  const codeLabel = isRedeemingCampaign ? t('campaign') : t('land');
  const codeLogoAlt = `Wilderness International ${isRedeemingCampaign ? 'Campaign' : 'Land'} Logo`;
  const codeLogo = isRedeemingCampaign ? campaign?.campaign_logo : couponDetail?.detail?.land?.region_logo;
  const codeName = isRedeemingCampaign
    ? campaign?.name?.[languageDefault?.code?.toLowerCase()]
    : couponDetail?.detail?.land?.region_name?.[languageDefault?.code?.toLowerCase()];

  const calculatorPosistion = () => {
    const mapInfo: any = {};
    mapInfo.area = couponDetail?.detail?.wi_code_batch?.area;
    mapInfo.title = couponDetail?.detail?.campaign?.name.de || couponDetail?.detail?.land?.region_name?.de;
    mapInfo.name = couponDetail?.detail?.wi_code_batch?.name;
    mapInfo.date = couponDetail?.detail?.wi_code_batch?.created_at;
    mapInfo.lat = couponDetail?.detail?.batch_geo_coordinate?.latitude;
    mapInfo.lng = couponDetail?.detail?.batch_geo_coordinate?.longitude;

    if (couponDetail?.detail) {
      return `${env.GATSBY_CMS_URL}/map?id=${generateMapUrl({ ...mapInfo })}`;
    }
    return '#';
  };

  useDidMountEffect(() => {
    if (!isStepDis) {
      clearTimeout(timeoutAnimation);
      if (donationRef && donationRef.current) {
        timeoutAnimation = setTimeout(() => {
          donationRef.current.scrollIntoView();
        }, 500);
      }
    }
  }, [isStepDis]);

  return (
    <div className="claim-coupon-component">
      <div className="claim-coupon-container">
        <div className="claim-coupon-header">
          <div className="claim-coupon-description">
            <div className="content-text">
              <div className="icon-content">
                <div className="icon-container">
                  <div className="completed-container">
                    <i className="fa-solid fa-circle-check"></i>
                  </div>
                  <h3>{t('couponDes')}</h3>
                </div>
              </div>
              <div className="description-content">
                <p>
                  <span className="text-detail">{couponDetail?.detail?.wi_code_batch?.area} m²</span> {t('couponDes1')}{' '}
                  {codeName}
                  {/* <a href={calculatorPosistion()} target="_blank" className="map-container" ><span className="icon-map"><i className="fa-solid fa-map-location-dot"></i></span></a> */}
                </p>
                {couponDetail?.detail?.partner?.partner_name && (
                  <p>
                    {t('couponDesMore')}{' '}
                    <span className="text-detail">{couponDetail?.detail?.partner?.partner_name}</span>
                  </p>
                )}

                {/* <div className="map-btn-container">
                                    <div className="show-map">
                                        <div className="map-btn">
                                            <a href={calculatorPosistion()} target="_blank"><span className="text">{t('showMap')}</span> <span className="icon-map"><i className="fa-solid fa-map-location-dot"></i></span></a>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>

          <div className="land-container">
            <div className="land-content">
              <div className="land-info">
                <div className="land-image-container">
                  <img src={`${env.IMAGE_URL}/${codeLogo}`} alt={codeLogoAlt} />
                </div>
                <div className="item-content">
                  <div className="content-container">
                    <label>{codeLabel}:</label>
                    <p>{codeName}</p>
                  </div>
                </div>
              </div>
              <div className="options-btn">
                <SelectButton
                  className="donation-checkbox"
                  options={OPTION_LIST[language?.code?.toUpperCase()]}
                  optionLabel="name"
                  optionValue="code"
                  onChange={(item: any) => {
                    if (item && item.value) {
                      setOption(item.value);
                    }
                  }}
                  value={option}
                />
              </div>
            </div>
          </div>
        </div>
        <AnimateHeight duration={500} height={option === VALUE_OPTION.DONATION && !isStepDis ? `auto` : 0}>
          <div className="donation">
            <ConverterCommon
              t={t}
              i18n={i18n}
              campaign={campaign}
              region={region}
              parameters={parameters}
              donationRef={donationRef}
              language={language}
              setLanguage={setLanguage}
              calcValues={calcValues}
              setCalcValues={setCalcValues}
              currency={currency}
              setCurrency={setCurrency}
              regions={regions}
              setRegion={setRegion}
              visible={option === VALUE_OPTION.DONATION}
              isClaim={option}
              couponDetail={couponDetail}
              rhythmData={rhythmData}
              setRhythmData={setRhythmData}
              page={page}
              option={option}
              isRhythmNo={isRhythmNo}
            />
          </div>
        </AnimateHeight>
        <DonationForm
          language={languageDefault}
          t={t}
          isClaim={option}
          couponCode={couponDetail?.code || coupon}
          couponDetail={couponDetail}
          calcValues={calcValues}
          region={region}
          currency={currency}
          isStepDis={isStepDis}
          setIsStepDis={setIsStepDis}
          page={page}
          direction={direction}
          setPage={setPage}
          donationRef={donationRef}
          parameters={parameters}
          rhythmData={rhythmData}
          campaign={campaign}
          setIsRhythmNo={setIsRhythmNo}
        />
      </div>
    </div>
  );
};

export default CouponComponent;
