import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ConverterParameters from '../components/converter/models/ConverterParameters';
import { useEffect, useState } from 'react';
import {
  getLanguageDefault,
  STATUS_COUPON,
  AreaList,
  CurrencyList,
  navigateUrl,
  Status_Region,
  URL,
} from '../utils/Utils';
import { withTranslation } from 'react-i18next';
import LoadingComponent from '../common/Loading';
import { fetchCouponsData, filterRegions } from '../utils/funtionCommon';
import CouponComponent from '../components/coupon/coupon-component';
import DonationFormAPI from '../services/donationFormAPI';

const CouponPage = ({ location, t, i18n }) => {
  const parameters = new ConverterParameters(location.search);
  const coupon = parameters.getCouponCode();
  const [couponDetail, setCouponDetail] = useState<any>({
    detail: null,
    area: 0,
  });

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();
  const rhythm = parameters.getRhythm();
  const [area, setArea] = useState(AreaList[0]);
  const [currency, setCurrency] = useState(() => {
    return CurrencyList.find(c => c.code === parameters.getCurrency());
  });

  const onAreaChange = e => {
    setArea(e.value);
  };

  const onCurrenyChange = e => {
    setCurrency(e.value);
    localStorage.setItem('currency_code', e.value.code);
  };

  const getRegionByCode = async (code: string) => {
    try {
      const { data: selectedRegion } = await DonationFormAPI.getRegionByCode(code);

      if (selectedRegion.status !== Status_Region.Active) {
        navigateUrl(`/${URL.NOT_FOUND}`, 'Region_Completed');
      }

      if (!selectedRegion) {
        navigateUrl(`/${URL.NOT_FOUND}`);
      }

      setRegion(selectedRegion);
      setRegions(filterRegions(selectedRegion.regions) || []);
    } catch (error) {
      navigateUrl(`/${URL.NOT_FOUND}`, error?.response?.status === 404 ? 'Region_NotFound' : '');
    }
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
    fetchCouponsData(coupon, setCouponDetail, false, false);
  }, []);

  useEffect(() => {
    if (couponDetail?.detail) {
      getRegionByCode(couponDetail?.detail?.land?.region_code);
    }
  }, [couponDetail.detail]);

  return couponDetail && couponDetail.detail && couponDetail.detail.coupon_status.status === STATUS_COUPON.ACTIVE ? (
    <Layout
      area={area}
      currency={currency}
      onCurrenyChange={onCurrenyChange}
      onAreaChange={onAreaChange}
      isShowAction={true}
      minHeight="100vh"
      location={location}
      style={{ background: '#F7F7F7' }}
    >
      <Seo title="Claim Code" />
      <div className="claim-coupon-page">
        <CouponComponent
          coupon={coupon}
          campaign={
            couponDetail?.detail?.campaign
              ? {
                  ...couponDetail?.detail?.campaign,
                  match_funding: couponDetail?.detail?.match_funding,
                  partner_logo: couponDetail?.detail?.partner?.partner_logo,
                  partner_name: couponDetail?.detail?.partner?.partner_name,
                }
              : null
          }
          couponDetail={couponDetail}
          t={t}
          i18n={i18n}
          languageDefault={getLanguageDefault()}
          parameters={parameters}
          regions={regions}
          region={region}
          setRegion={setRegion}
          currency={currency}
          setCurrency={setCurrency}
          rhythm={rhythm}
        />
      </div>
    </Layout>
  ) : (
    <LoadingComponent />
  );
};

export default withTranslation('blog')(CouponPage);
